import { UNITED_STATES } from '@modules/trials/helpers/hasAvailableCountry'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { ArmGroup, ClinicalTrialKeyword, Keyword } from '@prisma/client'
import { mockTrialConditions } from './trialConditionMocks'

export const mockEligibilityCriteria = `
Inclusion Criteria:

Age ≥ 18 years
Biopsy proven prostate cancer with an intermediate risk feature defined as

Gleason score 7, PSA <20, T1-T2C or
Gleason score 6, PSA 10-20, T1-T2C or
Gleason Score 6 or 7, PSA <20, T2C
Localized disease based on staging investigations including bone scan, CT abdomen and pelvis, and any other clinically indicated staging investigations
Eligible for curative intent external beam radiotherapy
Able and willing to follow instructions and comply with protocol
Provide written informed consent prior to participation in the study
Karnofsky Performance Scale Score 70-100

Exclusion Criteria:

Have had a 11C-Choline PET scan performed within 4 weeks after any biopsies of the prostate (due to concern that acute post biopsy intraprostatic changes may affect scan accuracy)
BMI ≥ 30
The presence of a hip prosthesis
Bilirubin ≥ 20 µmol/L
AST or ALT ≥ 5 times the upper limits of normal
Serious medical conditions which may prevent a patient from tolerating therapy such as: congestive heart failure, unstable angina, unstable ventricular arrhythmia, uncontrolled psychiatric conditions, serious infections and/or uncontrolled diabetes.
Metastatic disease
Prostate cancer with only low risk features or any high risk feature with a PSA ≥20 or T3 disease
A history of previous carcinoma except for basal cell carcinoma
Age < 18 years
Prior treatment with hormonal therapy
AUA prostate symptom score > 20
Crohn's disease or ulcerative colitis
Patient is unable to comply adequately iwth bowel or bladder prep during CT simulation`

const armGroups: ArmGroup[] = [
  {
    createdAt: new Date('2021-09-20T21:07:49.483Z'),
    description:
      'Patients with locally advanced prostate adenocarcinoma receives Prostatectomy/Surgery with or without adjuvant or salvage radiotherapy',
    id: 5,
    isPrimaryTreatment: null,
    label: 'Prostatectomy/Surgery',
    nctId: 'NCT02102477',
    type: 'Experimental',
    updatedAt: new Date('2021-09-20T21:07:49.484Z'),
  },
  {
    createdAt: new Date('2021-09-20T21:07:49.757Z'),
    description:
      'Patients with locally advanced prostate adenocarcinoma treated with adjuvant androgen deprivation therapy',
    id: 6,
    isPrimaryTreatment: null,
    label: 'Radiotherapy with adjuvant androgen deprivation therapy',
    nctId: 'NCT01420965',
    type: 'ActiveComparator',
    updatedAt: new Date('2021-09-20T21:07:49.758Z'),
  },
]

export const cancerInterventions: TrialAssociation['interventions'] = [
  {
    armGroupsJoin: [],
    createdAt: new Date('2021-09-06T23:06:36.832Z'),
    description: 'Radio therapy treatment',
    drugbankInterventionId: '1',
    drugsJoin: [],
    id: 7017,
    isPrimaryTreatment: null,
    name: 'Radio therapy',
    nctId: 'NCT01420965',
    otherNames: [],
    type: 'Drug',
    updatedAt: new Date('2021-09-17T23:29:02.355Z'),
  },
  {
    armGroupsJoin: [],
    createdAt: new Date('2021-09-06T23:06:36.832Z'),
    description: 'Prostatectomy',
    drugbankInterventionId: '2',
    drugsJoin: [],
    id: 7018,
    isPrimaryTreatment: null,
    name: 'Surgery',
    nctId: 'NCT01420965',
    otherNames: [],
    type: 'Procedure',
    updatedAt: new Date('2021-09-17T23:29:02.355Z'),
  },
]

const keywords: (ClinicalTrialKeyword & { keyword: Keyword })[] = [
  {
    createdAt: new Date('2021-09-06 23:06:38.092'),
    id: 12363,
    keyword: {
      createdAt: new Date('2021-09-06 23:06:38.044'),
      id: 8882,
      keyword: 'Anti PD - 1 Monoclonal Antibody',
      meshId: null,
      updatedAt: new Date('2021-09-06 23:06:38.045'),
    },
    keywordId: 8882,
    nctId: 'NCT01420965',
    updatedAt: new Date('2021-09-06 23:06:38.093'),
  },
  {
    createdAt: new Date('2021-09-06 23:06:38.184'),
    id: 12364,
    keyword: {
      createdAt: new Date('2021-09-06 22:30:24.064'),
      id: 1939,
      keyword: 'Vaccine',
      meshId: null,
      updatedAt: new Date('2021-09-06 22:30:24.065'),
    },
    keywordId: 1939,
    nctId: 'NCT01420965',
    updatedAt: new Date('2021-09-06 23:06:38.185'),
  },
]

const nctId = 'NCT01420965'

export const mockCollaborators: (
  nctId?: string,
) => TrialAssociation['collaborators'] = (nctId: string = 'NCT01420965') => {
  return [
    {
      createdAt: new Date('2021-09-06T22:30:24.064Z'),
      id: 1,
      isLead: true,
      nctId,
      organization: {
        class: 'INDUSTRY',
        id: 1,
        name: 'ACADIA Pharmaceuticals Inc.',
      },
      organizationId: 1,
      updatedAt: new Date('2021-09-06T22:30:24.065Z'),
    },
    {
      createdAt: new Date('2021-09-06T22:30:24.064Z'),
      id: 2,
      isLead: false,
      nctId,
      organization: {
        class: 'OTHER',
        id: 2,
        name: 'Scott Snapper MD PHD',
      },
      organizationId: 2,
      updatedAt: new Date('2021-09-06T22:30:24.065Z'),
    },
  ]
}

export const mockTrial: TrialAssociation = {
  abbreviatedTitle: null,
  acronym: null,
  adverseEventGroups: [],
  adverseEventsDescription: null,
  adverseEventsFrequencyThreshold: null,
  adverseEventsTimeFrame: null,
  ageList: ['Adult', 'Older Adult'],
  agreementOtherDetails: null,
  agreementPISponsorEmployee: null,
  agreementRestrictionType: null,
  agreementRestrictiveAgreement: null,
  allowListedAt: null,
  armGroups,
  awardBadges: [
    {
      data: {},
      deletedAt: null,
      id: 1,
      label: 'label',
      name: 'name',
      tags: ['general'],
      whyThisMatters: 'because',
    },
  ],
  baselinePopulationDescription: null,
  baselineTypeUnitsAnalyzed: null,
  bioSpecDescription: null,
  bioSpecRetention: null,
  biologicalSex: 'Male',
  biologicalSexBased: null,
  biologicalSexDescription: null,
  briefSummary: 'Summary',
  briefTitle: 'brief title',
  centralContacts: [],
  chartImages: [],
  collaborators: mockCollaborators(nctId),
  conditionAncestors: [],
  conditions: mockTrialConditions,
  conjuredTitle: 'Experimental Drug for Example Condition',
  createdAt: new Date('2021-09-06T23:06:36.635Z'),
  davinciBriefSummary: {
    stage1: 'This is the Davinci Stage-1 Brief Summary',
    stage2: 'This is the Davinci Stage-2 Brief Summary',
  },
  davinciBriefSummaryDate: new Date('2021-09-17T23:28:58.277Z'),
  designAllocation: 'NonRandomized',
  designInterventionModel: null,
  designInterventionModelDescription: null,
  designMaskingInfo: null,
  designObservationalModelList: [],
  designPrimaryPurpose: null,
  designTimePerspectiveList: [],
  detailedDescription: 'Description',
  disableTrialApplyTrialProNotifications: false,
  drugStatus: [],
  eligibilityCriteria: mockEligibilityCriteria,
  enrollmentCount: null,
  enrollmentType: null,
  expandedAccessTypes: null,
  faqs: [],
  gender: 'Male',
  genderBased: null,
  genderDescription: null,
  hasExpandedAccess: null,
  hasFDAAA801Violation: null,
  hasSponsorVerifiedData: false,
  healthyVolunteers: false,
  helpfulInformationForPatients: [],
  id: 4848,
  interventionMeshes: [],
  interventions: [
    {
      armGroupsJoin: [],
      createdAt: new Date('2021-09-06T23:06:36.832Z'),
      description: 'Immune Enhancer',
      drugbankInterventionId: '3',
      drugsJoin: [],
      id: 7017,
      isPrimaryTreatment: null,
      name: 'CT-011 (Anti-PD1 Antibody)',
      nctId: 'NCT01420965',
      otherNames: ['Anti-PD1 Antibody'],
      type: 'Drug',
      updatedAt: new Date('2021-09-17T23:29:02.355Z'),
    },
    {
      armGroupsJoin: [],
      createdAt: new Date('2021-09-06T23:06:36.933Z'),
      description: 'Low dose- Immune Enhancer',
      drugbankInterventionId: '4',
      drugsJoin: [],
      id: 7019,
      isPrimaryTreatment: null,
      name: 'Cyclophosphamide',
      nctId: 'NCT01420965',
      otherNames: ['Cytoxan'],
      type: 'Drug',
      updatedAt: new Date('2021-09-17T23:29:04.077Z'),
    },
    {
      armGroupsJoin: [],
      createdAt: new Date('2021-09-06T23:06:36.884Z'),
      description: 'Vaccine',
      drugbankInterventionId: '5',
      drugsJoin: [],
      id: 7018,
      isPrimaryTreatment: null,
      name: 'Sipuleucel-T (Provenge)',
      nctId: 'NCT01420965',
      otherNames: ['Provenge'],
      type: 'Other',
      updatedAt: new Date('2021-09-17T23:29:03.175Z'),
    },
  ],
  ipdSharing: 'No',
  ipdSharingDescription: null,
  ipdSharingInfoType: [],
  ipdSharingTimeFrame: null,
  ipdSharingUrl: null,
  isFDARegulatedDevice: false,
  isFDARegulatedDrug: true,
  isPPSD: null,
  isUSExport: null,
  isUnapprovedDevice: null,
  largeDocuments: null,
  lastUpdatedDate: new Date('2019-06-18T00:00:00.000Z'),
  limitationsAndCaveatsDescription: null,
  maximumAge: null,
  maximumBmi: null,
  minimumAge: '18 Years',
  minimumBmi: null,
  mixedCaseSlug: 'phase-3-Prostatic-Neoplasms-8-2012-9aa3c',
  nctId,
  nlpBriefSummary: 'This is the NLP Brief Summary',
  officialTitle: 'official title',
  orgStudyIdInfo: {
    OrgStudyId: '11C0231',
  },
  outcomes: [],
  overallOfficials: [],
  overallStatus: 'Terminated',
  overrideAt: null,
  overrideBy: null,
  overrides: null,
  oversightHasDMC: true,
  parsedEligibilityCriteria: [],
  patientRegistry: null,
  phaseList: ['Phase 2'],
  preassignmentDetails: null,
  primaryCompletionDate: new Date('2016-12-01T00:00:00.000Z'),
  recruitmentDetails: null,
  references: [],
  reimbursements: [],
  removedCountries: [],
  responsibleParty: 'PrincipalInvestigator',
  responsiblePartyInvestigatorAffiliation: 'Augusta University',
  responsiblePartyInvestigatorFullName: 'Samir N. Khleif',
  responsiblePartyInvestigatorTitle: 'Cancer Center Director',
  responsiblePartyOldNameTitle: null,
  responsiblePartyOldOrganization: null,
  resultsPointOfContactEmail: null,
  resultsPointOfContactOrganization: null,
  resultsPointOfContactPhone: null,
  resultsPointOfContactPhoneExt: null,
  resultsPointOfContactTitle: null,
  samplingMethod: null,
  secondaryIdInfoList: null,
  slug: 'phase-3-prostatic-neoplasms-8-2012-9aa3c',
  sponsorApprovalEnabledAt: null,
  sponsorUgc: null,
  stageOfDisease: [],
  stageOfTreatment: [],
  statusVerifiedDate: new Date('2019-06-01T00:00:00.000Z'),
  studyCompletionDate: new Date('2016-12-01T00:00:00.000Z'),
  studyPopulation: null,
  studyStartDate: new Date('2012-09-01T00:00:00.000Z'),
  studyType: 'Interventional',
  targetDuration: null,
  therapeuticAreas: [],
  timeline: null,
  treatments: [],
  trialLocations: [
    {
      address: null,
      address2: null,
      autoDisqualify: false,
      city: 'Augusta',
      country: UNITED_STATES,
      facility: 'Georgia Regents University',
      facilityJoin: null,
      id: 19677,
      images: [],
      lat: null,
      lng: null,
      monetization: null,
      nctId: 'NCT01420965',
      state: 'Georgia',
      status: 'Recruiting',
      zip: '30912',
    },
  ],
  unpostedAnnotation: null,
  updatedAt: new Date('2021-09-17T23:28:58.277Z'),
  versionHolder: 'September 17, 2021',
  video: null,
  whyStudyStopped: 'Drug supply issues',
}

export const mockTrialInDatabaseForAlgolia: TrialInDatabaseForAlgolia = {
  ...mockTrial,
  conditions: [
    {
      childTerms: [],
      condition: {
        colloquialAcronym: 'N/A',
        colloquialTerm: 'Prostate Cancer',
        colloquialTermStripped: 'Prostate Cancer',
        createdAt: new Date('2021-09-06T22:10:41.665Z'),
        drugbankId: 'DBC000001',
        drugbankTableId: 1,
        drugsJoin: [
          {
            conditionId: 167,
            createdAt: new Date('2021-09-06T22:10:41.665Z'),
            drug: {
              approved: false,
              biotechCategoryGroupName: null,
              biotechCategoryName: null,
              biotechKind: null,
              casNumber: null,
              clinicalDescription: null,
              createdAt: new Date('2021-09-06T22:10:41.665Z'),
              description: null,
              drugbankId: 'DB00001',
              drugbankTableId: 1,
              experimental: true,
              id: 1,
              illicit: false,
              investigational: true,
              meshId: null,
              name: 'Something',
              nlpDescription: null,
              nutraceutical: false,
              proteinFormula: null,
              proteinWeight: null,
              proteinWeightDetails: null,
              simpleDescription: null,
              state: null,
              synonyms: [],
              synthesisPatentId: null,
              type: 'SmallMoleculeDrug',
              updatedAt: new Date('2021-09-18T20:19:06.105Z'),
              vetApproved: false,
              withdrawn: false,
            },
            drugId: 1,
            id: 1,
            kind: '',
            updatedAt: new Date('2021-09-18T20:19:06.105Z'),
          },
        ],
        icd10Id: '',
        id: 167,
        medraId: '',
        meshId: 'D000011471',
        name: 'Prostatic Neoplasms',
        preferredTerm: 'Prostate Cancer',
        snomedId: '',
        synonyms: [''],
        therapeuticAreaId: null,
        umlsParentCategories: [],
        umlsTreeNumber: '',
        updatedAt: new Date('2021-09-18T20:19:06.105Z'),
      },
      conditionId: 167,
      createdAt: new Date('2021-09-06T23:06:37.996Z'),
      id: 8634,
      inConditionsModule: true,
      isPrimaryCondition: false,
      nctId: 'NCT01420965',
      parentTerms: [],
      trialEditId: null,
      updatedAt: new Date('2021-09-17T23:29:23.784Z'),
    },
  ],
  conjuredFaq: null,
  keywords,
  managingTrialPros: [],
  parsedEligibilityCriteria: [],
  treatments: [],
  trialBrowseConditions: [],
  trialBrowseInterventions: [],
  trialLocations: [
    {
      address: null,
      address2: null,
      autoDisqualify: false,
      centralSite: null,
      city: 'Augusta',
      contacts: [
        {
          contact: {
            email: 'here@example.com',
            id: 1,
            name: 'John Doe',
            phone: '5551234567',
            phoneExt: null,
            principalInvestigator: null,
            profilePhoto: null,
            relayThreads: [
              {
                emailRelaysToPatient: [{ id: 1 }],
                phoneRelays: [{ id: 1 }],
                smsRelays: [{ id: 1, recipient: 'Patient' }],
              },
            ],
            role: 'Principal Investigator',
          },
          contactId: 1,
          status: 'Verified',
        },
      ],
      country: UNITED_STATES,
      facility: 'Georgia Regents University',
      facilityJoin: null,
      id: 19677,
      images: [],
      lat: null,
      lng: null,
      managingTrialPros: [],
      monetization: null,
      nctId: 'NCT01420965',
      state: 'Georgia',
      status: 'Recruiting',
      zip: '30912',
    },
  ],
}
